import React from 'react'
import PropTypes from 'prop-types'
import { getCategoriesWithTransferAccounts } from './queries'
import Selector from './Selector'
import { createCategory } from './persistence'

export default function CategorySelector({
  category,
  onChange,
  onKeyDown,
  className,
}: {
  category: string
  onChange: (c: string) => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  className?: string
}) {
  return (
    <Selector
      value={category}
      queryKey="categories_with_transfer_accounts"
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={className}
      queryFn={getCategoriesWithTransferAccounts}
      onNewValue={createCategory}
    />
  )
}

CategorySelector.propTypes = {
  category: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
}
