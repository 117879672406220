import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getAccount } from './queries'
import { updateAccount } from './persistence'
import { Account, AccountType } from '../../types'
import MoneyAmount from '../../MoneyAmount'

export default function AccountEdit() {
  const { accountId } = useParams<{ accountId: string }>()

  const {
    isLoading,
    isError,
    data: account = {} as Account,
  } = useQuery({
    queryKey: ['account', accountId],
    queryFn: () => getAccount(accountId || 'none'), // TODO: Review this none
  })

  const [hidden, setHidden] = useState(account.hidden || false)
  const [name, setName] = useState<string>(account.name || '')
  const [type, setType] = useState<AccountType>(account.type || 'asset')

  const queryClient = useQueryClient()
  const updateMutation = useMutation({
    mutationFn: async (a: Account) =>
      updateAccount({ ...a, hidden, name, type }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['accounts'] }),
  })

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    updateMutation.mutate(account)
  }

  const handleHiddenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHidden(event.target.checked)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value as AccountType)
  }

  useEffect(() => {
    if (account && account.id) {
      setHidden(account.hidden)
      setName(account.name)
      setType(account.type)
    }
  }, [account])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error loading account</div>
  }

  return (
    <div className="p-5">
      <h2 className="leading-5 text-lg my-3">Editing Account</h2>

      <form onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-2 gap-y-3 w-96">
          <label htmlFor="accountId">ID:</label>
          <div>{account.id}</div>

          <label htmlFor="balance">Balance:</label>
          <MoneyAmount amountInCents={account.balance} showCents color />

          <label htmlFor="name">Name:</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={handleNameChange}
          />

          <label htmlFor="hidden">Hidden:</label>
          <div>
            <input
              type="checkbox"
              name="hidden"
              id="hidden"
              checked={hidden}
              value=""
              onChange={handleHiddenChange}
            />
          </div>

          <label htmlFor="type">Type:</label>
          <select name="type" value={type} onChange={handleTypeChange}>
            <option value="INCOME">Income</option>
            <option value="EXPENSE">Expense</option>
            <option value="ASSET">Asset</option>
            <option value="LIABILITY">Liability</option>
          </select>

          <div />
          <div>
            <button type="submit" className="bg-blue-500 p-2 rounded">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
