// import { Transaction } from "./types";

import { authenticatedFetch } from '../../auth-utils'
import { Transaction } from '../types'

export type TransactionsResponse = {
  transactions: Transaction[]
  startingBalance: number
  endingBalance: number
  count: number
}

export const getTransactions = async ({
  accountId,
  startDate,
  endDate,
  searchTerm,
}: {
  accountId: string
  startDate: string
  endDate: string
  searchTerm: string
}): Promise<TransactionsResponse> => {
  const params = new URLSearchParams({
    accountId,
    startDate,
    endDate,
    searchTerm,
  })
  const response = await authenticatedFetch(
    `/transactions?${params.toString()}`,
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}
