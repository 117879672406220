import classNames from 'classnames'
import React from 'react'

interface TransactionPresenterProps {
  className?: string
  date?: React.ReactNode
  payee?: React.ReactNode
  category?: React.ReactNode
  tag?: React.ReactNode
  description?: React.ReactNode
  amount?: React.ReactNode
  balanceOrActions: React.ReactNode
  selected?: boolean
  editing?: boolean
  rowRef?: React.RefObject<HTMLDivElement>
  onClick?: () => void | null
  onDblClick?: () => void | null
}

export default function TransactionPresenter({
  className,
  date,
  payee,
  category,
  tag,
  description,
  amount,
  balanceOrActions,
  selected,
  editing,
  rowRef,
  onClick,
  onDblClick,
}: TransactionPresenterProps) {
  const handleClick = () => onClick?.()
  const handleDblClick = () => onDblClick?.()

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames(className, 'border flex', {
        'tx-selected-color': selected && !editing,
        'tx-editing-color': editing,
      })}
      onClick={handleClick}
      onDoubleClick={handleDblClick}
      ref={rowRef}
    >
      <div className="w-24 leading-6 flex-shrink-0">{date}</div>
      <div className="w-36 leading-6 flex-grow-0 flex-shrink-0">{payee}</div>
      <div className="w-64 leading-6 flex-grow-0 flex-shrink-0">{category}</div>
      <div className="w-52 leading-6 flex-grow-0 flex-shrink-0">{tag}</div>
      <div className="w-36 leading-6 flex-grow">{description}</div>
      <div className="w-20 leading-6 flex-grow-0 flex-shrink-0 text-right">
        {amount}
      </div>
      <div className="w-24 leading-6 flex-grow-0 flex-shrink-0 text-right">
        {balanceOrActions}
      </div>
    </div>
  )
}
