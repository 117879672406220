import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { moneyInMajors } from '../util'

export default function MoneyAmount({
  amountInCents,
  showCents = true,
  color,
  inverse,
}: {
  amountInCents: number
  showCents?: boolean
  color?: boolean
  inverse?: boolean
}) {
  const amount = moneyInMajors(amountInCents) * (inverse ? -1 : 1)
  const digits = showCents ? 2 : 0
  const formattedAmount = amount.toLocaleString('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })

  const classes = classNames({
    'text-red-700': color && amount < 0,
    'text-blue-700': color && amount > 0,
  })

  return <span className={classes}>{formattedAmount}</span>
}

MoneyAmount.propTypes = {
  amountInCents: PropTypes.number.isRequired,
  showCents: PropTypes.bool,
  inverse: PropTypes.bool,
}
