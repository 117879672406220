import { authenticatedFetch } from '../../auth-utils'

export const getIntegrity = async (): Promise<true> => {
  const response = await authenticatedFetch('/verify_integrity')
  if (!response.ok) {
    const errors = await response.json()
    if (errors) {
      throw new Error(`Integrity error: ${errors.join(', ')}`)
    }
  }

  return true
}
