import React from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid'

import { getIntegrity } from './queries'

export default function IntegrityIndicator() {
  const { error, isFetching } = useQuery({
    queryKey: ['integrity'],
    queryFn: getIntegrity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  })

  if (error) {
    return (
      <ExclamationCircleIcon
        className="text-red-500"
        title={`Integrity Error: ${error}`}
      />
    )
  }

  if (isFetching) {
    return (
      <ClockIcon
        className="text-blue-500"
        title="Integrity check in progress"
      />
    )
  }

  return <CheckCircleIcon className="text-green-500" title="Integrity OK" />
}
