import React from 'react'
import { incrementDate, todayAsString } from '../../util'

export default function DateEditor({
  autoFocus,
  date,
  disabled,
  onDateChange,
  onKeyDown,
  className,
}: {
  autoFocus?: boolean
  date: string
  disabled?: boolean
  onDateChange: (newDate: string) => void
  onKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement>) => void
  className?: string
}) {
  const handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
    onDateChange(evt.currentTarget.value)
  }

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    switch (evt.key) {
      case '+':
      case '=': // for the + key without shift
        onDateChange(incrementDate(date, 1))
        break
      case '-':
        onDateChange(incrementDate(date, -1))
        break
      case 't':
        onDateChange(todayAsString())
        break
      default:
        break
    }

    onKeyDown?.(evt)
  }

  return (
    <input
      type="date"
      className={className}
      value={date}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
    />
  )
}
