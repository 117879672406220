import { authenticatedFetch } from '../../../auth-utils'
import {
  Account,
  AccountCategory,
  AccountHiddenFilter,
  AccountSummary,
} from '../../types'

export const getAccounts = async ({
  hiddenFilter,
  accountCategory,
}: {
  hiddenFilter: AccountHiddenFilter
  accountCategory: AccountCategory
}): Promise<Account[]> => {
  const params = new URLSearchParams({
    hidden: hiddenFilter,
    accountCategory,
  })
  const response = await authenticatedFetch(`/accounts?${params.toString()}`)

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}

export const getAccountSummary = async (): Promise<AccountSummary> => {
  const response = await authenticatedFetch('/accounts/summary')

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}
