import { authenticatedFetch } from '../../auth-utils'
import { CashFlowDashboardData, InOutDashboardData } from './types'

export const getInOutDashboard = async (): Promise<InOutDashboardData> => {
  const response = await authenticatedFetch('/reports/in_out')

  if (!response.ok) {
    throw new Error(`Error fetching data: ${response.statusText}`)
  }

  return response.json()
}

export const getCashFlowDashboard = async ({
  startDate,
  endDate,
  limit,
}: {
  startDate: string
  endDate: string
  limit: number
}): Promise<CashFlowDashboardData> => {
  const params = new URLSearchParams({
    startDate,
    endDate,
    limit: limit.toString(),
  })
  const response = await authenticatedFetch(
    `/reports/cash_flow_by_account?${params.toString()}`,
  )

  if (!response.ok) {
    throw new Error(`Error fetching data: ${response.statusText}`)
  }

  return response.json()
}
