import { authenticatedFetch } from '../../auth-utils'
import { Transaction, TRANSACTION_NEW_ID } from '../types'

const updateTransaction = async (
  transaction: Transaction,
): Promise<Transaction> => {
  const response = await authenticatedFetch(`/transactions/${transaction.id}`, {
    method: 'PUT',
    body: JSON.stringify(transaction),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.json()
}

const createTransaction = async (
  transaction: Transaction,
): Promise<Transaction> => {
  const response = await authenticatedFetch('/transactions', {
    method: 'POST',
    body: JSON.stringify(transaction),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.json()
}

export const saveTransaction = (
  transaction: Transaction,
): Promise<Transaction> => {
  if (transaction.id === TRANSACTION_NEW_ID) {
    return createTransaction(transaction)
  }

  return updateTransaction(transaction)
}

export const deleteTransaction = async (
  transactionId: string,
): Promise<void> => {
  await authenticatedFetch(`/transactions/${transactionId}`, {
    method: 'DELETE',
  })
}
