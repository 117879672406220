import React from 'react'
import { Link } from 'react-router-dom'
import { useIsFetching } from '@tanstack/react-query'

import { clearAuthToken } from '../auth-utils'
import AccountList from './Account/AccountList/AccountList'
import IntegrityIndicator from './IntegrityIndicator/IntegrityIndicator'

export default function MainPage({
  children,
}: {
  children?: JSX.Element
}): JSX.Element {
  const handleLogout = () => {
    clearAuthToken()
    window.location.href = '/'
  }
  const fetching = useIsFetching()

  return (
    <div className="w-full h-screen">
      <header className="p-3 bg-black text-white flex w-full fixed h-12">
        <Link to="/" className="left-0">
          GoFinance
        </Link>

        <div className="ml-3 text-green-500" title="Loading">
          {Array.from({ length: fetching }).map((_: unknown, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>&#9679;</React.Fragment>
          ))}
        </div>

        <div className="ml-auto flex gap-3">
          <IntegrityIndicator />

          <Link to="/logout" onClick={() => handleLogout()}>
            Logout
          </Link>
        </div>
      </header>
      <div className="flex h-full pt-12 w-screen">
        <aside className="top-0 w-52 text-sm flex-grow-0 flex-shrink-0 overflow-y-auto h-full">
          <AccountList />
        </aside>
        <main className="bg-yellow-50 overflow-y-auto h-full w-full flex-row">
          {children}
        </main>
      </div>
    </div>
  )
}
