import { authenticatedFetch } from '../../auth-utils'

export const createCategory = async (name: string): Promise<void> => {
  const response = await authenticatedFetch('/categories', {
    method: 'POST',
    body: JSON.stringify({ name }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.json()
}

export const createTag = async (name: string): Promise<void> => {
  const response = await authenticatedFetch('/tags', {
    method: 'POST',
    body: JSON.stringify({ name }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.json()
}
