import { authenticatedFetch } from '../../../auth-utils'
import { Account } from '../../types'

export const getAccount = async (accountId: string): Promise<Account> => {
  const response = await authenticatedFetch(`/accounts/${accountId}`)

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}
