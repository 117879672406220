/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-autofocus */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CheckCircleIcon,
  LinkIcon,
  ListBulletIcon,
  PlusCircleIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'

import { Transaction, TRANSACTION_NEW_ID, TransactionSplit } from '../types'
import { deleteTransaction, saveTransaction } from './persistence'
import CategorySelector from '../Selector/CategorySelector'
import TransactionPresenter from './TransactionPresenter'
import AmountEditor from './AmountEditor'
import MoneyAmount from '../MoneyAmount'
import { formatSplitText } from './transaction'
import DateEditor from '../DateEditor/DateEditor'
import TagSelector from '../Selector/TagSelector'
import PayeeSelector from '../Selector/PayeeSelector'
import { getTxAutocomplete } from './queries'
import { transactionsRoute } from '../../routes'

export type TransactionEntryEditOutcome = 'saved' | 'canceled'

export default function TransactionEntryEdit({
  transaction,
  rowRef,
  onComplete,
}: {
  transaction: Transaction
  rowRef: React.RefObject<HTMLDivElement>
  onComplete: (outcome: TransactionEntryEditOutcome) => void
}) {
  const [date, setDate] = useState<string>(transaction.date)
  const [payee, setPayee] = useState<string>(transaction.payee)
  const [description, setDescription] = useState<string>(
    transaction.description,
  )
  const [amount, setAmount] = useState<number>(transaction.amount)
  const [splits, setSplits] = useState<TransactionSplit[]>(transaction.splits)

  const hasSplits = splits.length > 1

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const serializeTransaction = (): Transaction => ({
    id: transaction.id,
    date,
    payee,
    description,
    amount,
    accountId: transaction.accountId,
    splits,
  })

  const handleMutationError = (error: Error) => {
    // eslint-disable-next-line no-alert
    alert(`Error saving transaction: ${error.message}`)
  }

  const handleMutationSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['transactions'] })
    queryClient.invalidateQueries({ queryKey: ['accounts'] })
    queryClient.invalidateQueries({ queryKey: ['account_summary'] })
    queryClient.invalidateQueries({ queryKey: ['integrity'] })
    onComplete('saved')
  }

  const saveMutation = useMutation({
    mutationFn: async (serializedTransaction: Transaction) =>
      saveTransaction(serializedTransaction),
    onError: handleMutationError,
    onSuccess: handleMutationSuccess,
  })

  const deleteMutation = useMutation({
    mutationFn: (transactionId: string) => deleteTransaction(transactionId),
    onError: handleMutationError,
    onSuccess: handleMutationSuccess,
  })

  const modifySplit = (
    index: number,
    overrides: Partial<TransactionSplit>,
  ): TransactionSplit[] => {
    const newSplits = [...splits]
    newSplits[index] = { ...newSplits[index], ...overrides }
    setSplits(newSplits)

    return newSplits
  }

  const updateTransactionAmountFromSplits = (newSplits: TransactionSplit[]) => {
    const transactionAmount = newSplits.reduce(
      (sum, split) => sum + split.amount,
      0,
    )
    setAmount(transactionAmount)
  }

  const handleGoToMatching = (split: TransactionSplit) => {
    if (split.matchAccountId) {
      navigate(
        transactionsRoute(split.matchAccountId, { focusId: split.matchId }),
      )
    }
  }

  const handleChangeDate = (newDate: string) => setDate(newDate)

  const handleChangePayee = async (newPayee: string) => {
    setPayee(newPayee)
    if (newPayee) {
      const [tx] = await getTxAutocomplete(newPayee)
      if (tx) {
        setDescription(tx.description)
        setSplits(
          tx.splits.map((split) => ({
            matchId: undefined,
            ...split,
          })),
        )
        updateTransactionAmountFromSplits(tx.splits)
      }
    }
  }

  const handleChangeDescription = (evt: React.FormEvent<HTMLInputElement>) => {
    const newDescription = evt.currentTarget.value
    setDescription(newDescription)
    if (!hasSplits) {
      modifySplit(0, { description: newDescription })
    }
  }

  const handleChangeAmount = (newAmountInCents: number) => {
    // This is triggered only when there are no splits
    modifySplit(0, { amount: newAmountInCents })
    setAmount(newAmountInCents)
  }

  const handleChangeSplitAmount =
    (index: number) => (newAmountInCents: number) => {
      const newSplits = modifySplit(index, { amount: newAmountInCents })
      updateTransactionAmountFromSplits(newSplits)
    }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSave = (_event: React.MouseEvent<HTMLButtonElement>) => {
    saveMutation.mutate(serializeTransaction())
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCancel = (_event: React.MouseEvent<HTMLButtonElement>) => {
    onComplete('canceled')
  }

  const handleAddSplit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const newSplits = [...splits]
    newSplits.push({
      category: '',
      tag: '',
      description,
      amount: 0,
    })
    setSplits(newSplits)
  }

  const handleKeyDown = (evt: React.KeyboardEvent): void => {
    if (evt.key === 'Enter') {
      saveMutation.mutate(serializeTransaction())
    } else if (evt.key === 'Escape') {
      onComplete('canceled')
    }
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    deleteMutation.mutate(transaction.id)
  }

  const handleDeleteSplit = (index: number) => {
    const newSplits = [...splits]
    newSplits.splice(index, 1)
    setSplits(newSplits)
    updateTransactionAmountFromSplits(newSplits)
  }

  const handleChangeSplitDescription =
    (index: number): ((evt: React.FormEvent<HTMLInputElement>) => void) =>
    (evt: React.FormEvent<HTMLInputElement>) => {
      modifySplit(index, { description: evt.currentTarget.value })
    }

  const handleChangeSplitCategory =
    (index: number): ((newCategory: string) => void) =>
    (newCategory: string) => {
      const newSplits = [...splits]
      newSplits[index].category = newCategory
      setSplits(newSplits)
    }

  const handleChangeSplitTag =
    (index: number): ((newTag: string) => void) =>
    (newTag: string) => {
      const newSplits = [...splits]
      newSplits[index].tag = newTag
      setSplits(newSplits)
    }

  const handleChangeCategory = (newCategory: string) => {
    modifySplit(0, { category: newCategory })
  }

  const handleChangeTag = (newTag: string) => {
    modifySplit(0, { tag: newTag })
  }

  const isInserting = (): boolean => transaction.id === TRANSACTION_NEW_ID

  // eslint-disable-next-line func-style
  function renderDeleteButton() {
    if (transaction.id === TRANSACTION_NEW_ID) {
      return null
    }

    return (
      <button
        type="button"
        value="Delete"
        title="Delete"
        onClick={handleDelete}
      >
        <TrashIcon className="h-4 w-4 text-red-700" />
      </button>
    )
  }

  const renderGoToMatchingButton = (split: TransactionSplit) => {
    if (split.matchAccountId) {
      return (
        <button
          type="button"
          value="Go To Matching"
          title="Go to matching transaction"
          onClick={() => handleGoToMatching(split)}
        >
          <LinkIcon className="h-4 w-4 text-blue-500" />
        </button>
      )
    }
    return null
  }

  const renderSplit = (split: TransactionSplit, index: number) => {
    const isLastSplit = index === splits.length - 1
    return (
      <TransactionPresenter
        key={index}
        category={
          <CategorySelector
            category={split.category}
            onChange={handleChangeSplitCategory(index)}
            className="tx-editing-color"
          />
        }
        tag={
          <TagSelector
            tag={split.tag}
            onChange={handleChangeSplitTag(index)}
            className="tx-editing-color"
          />
        }
        description={
          <input
            type="text"
            value={split.description}
            onChange={handleChangeSplitDescription(index)}
            onKeyDown={handleKeyDown}
            className="w-full tx-editing-color"
          />
        }
        amount={
          <AmountEditor
            amount={split.amount}
            onChange={handleChangeSplitAmount(index)}
            onKeyDown={handleKeyDown}
          />
        }
        balanceOrActions={
          <div>
            {isLastSplit && (
              <button
                type="button"
                value="Add Split"
                title="Add Split"
                onClick={handleAddSplit}
              >
                <PlusCircleIcon className="h-5 w-5 text-green-500" />
              </button>
            )}

            <button
              type="button"
              value="Delete"
              onClick={() => handleDeleteSplit(index)}
            >
              <TrashIcon className="h-5 w-5 text-red-700" />
            </button>
            {renderGoToMatchingButton(split)}
          </div>
        }
        selected={false}
        editing
      />
    )
  }

  return (
    <>
      <TransactionPresenter
        rowRef={rowRef}
        date={
          <DateEditor
            autoFocus={isInserting()}
            date={date}
            onDateChange={handleChangeDate}
            onKeyDown={handleKeyDown}
            className="w-full tx-editing-color"
          />
        }
        payee={
          <PayeeSelector
            payee={payee}
            onChange={handleChangePayee}
            onKeyDown={handleKeyDown}
            className="w-full tx-editing-color"
          />
        }
        category={
          hasSplits ? (
            formatSplitText(splits)
          ) : (
            <CategorySelector
              category={splits[0].category}
              onChange={(newCategory) => handleChangeCategory(newCategory)}
              onKeyDown={handleKeyDown}
              className="tx-editing-color"
            />
          )
        }
        tag={
          hasSplits ? (
            formatSplitText(splits)
          ) : (
            <TagSelector
              tag={splits[0].tag}
              onChange={(newTag) => handleChangeTag(newTag)}
              onKeyDown={handleKeyDown}
              className="tx-editing-color"
            />
          )
        }
        description={
          <input
            type="text"
            value={description}
            onChange={handleChangeDescription}
            onKeyDown={handleKeyDown}
            className="w-full tx-editing-color"
          />
        }
        amount={
          hasSplits ? (
            <MoneyAmount amountInCents={amount} color />
          ) : (
            <AmountEditor
              amount={amount}
              onChange={handleChangeAmount}
              onKeyDown={handleKeyDown}
            />
          )
        }
        balanceOrActions={
          <div>
            {!hasSplits && (
              <button
                type="button"
                value="Split"
                title="Split"
                onClick={handleAddSplit}
              >
                <ListBulletIcon className="h-4 w-4 text-green-700" />
              </button>
            )}
            <button
              type="button"
              value="Save"
              title="Save"
              onClick={handleSave}
            >
              <CheckCircleIcon className="h-4 w-4 text-green-700" />
            </button>
            <button
              type="button"
              value="Cancel"
              title="Cancel"
              onClick={handleCancel}
            >
              <XCircleIcon className="h-4 w-4 text-red-400" />
            </button>
            {renderDeleteButton()}
            {renderGoToMatchingButton(splits[0])}
          </div>
        }
        selected
        editing
      />
      {hasSplits && splits.map(renderSplit)}
    </>
  )
}
