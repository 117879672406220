/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  configure as reactHotKeysConfigure,
  GlobalHotKeys,
} from 'react-hotkeys'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import debounce from 'lodash/debounce'

import { Transaction, TRANSACTION_NEW_ID } from '../types'
import { getTransactions, TransactionsResponse } from './queries'
import TransactionEntry from '../TransactionEntry/TransactionEntry'
import { generateRandomUUID, todayAsString } from '../../util'
import TransactionPresenter from '../TransactionEntry/TransactionPresenter'
import { TransactionEntryEditOutcome } from '../TransactionEntry/TransactionEntryEdit'
import PeriodSelect, {
  defaultSelectPeriod,
  OnSelectPeriodArgs,
} from '../PeriodSelect/PeriodSelect'
import MoneyAmount from '../MoneyAmount'

const TRANSACTION_NEW_INDEX = -1

// This is used to mimic VIM long-press behaviour
reactHotKeysConfigure({ ignoreRepeatedEventsWhenKeyHeldDown: false })

export default function TransactionList() {
  const { accountId } = useParams<{ accountId: string }>() as {
    accountId: string
  }

  const [searchParams, setSearchParams] = useSearchParams({})
  const focusId = searchParams.get('focusId')

  const navigate = useNavigate()

  const [startDate, setStartDate] = useState(defaultSelectPeriod().startDate)
  const [endDate, setEndDate] = useState(defaultSelectPeriod().endDate)
  const [searchTerm, setSearchTerm] = useState('')

  const initialData: TransactionsResponse = {
    transactions: [],
    startingBalance: 0,
    endingBalance: 0,
    count: 0,
  }

  const {
    isLoading,
    isError,
    data: { transactions, startingBalance } = initialData,
  } = useQuery({
    queryKey: ['transactions', accountId, startDate, endDate, searchTerm],
    placeholderData: initialData,
    queryFn: () =>
      getTransactions({
        accountId: accountId || 'all',
        startDate,
        endDate,
        searchTerm,
      }),
  })

  const [editingTransaction, setEditingTransaction] =
    useState<Transaction | null>(null)
  const [isInsertingTransaction, setIsInsertingTransaction] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const transactionSum = useMemo(
    () => transactions.reduce((sum, t) => sum + t.amount, 0),
    [transactions],
  )

  const isModifyingTransaction = () =>
    !!editingTransaction || isInsertingTransaction

  const keyMap = {
    NEXT_TRANSACTION: 'j',
    NEXT_TRANSACTION_PAGE: 'shift+j',
    PREVIOUS_TRANSACTION: 'k',
    PREVIOUS_TRANSACTION_PAGE: 'shift+k',
    EDIT_TRANSACTION: ['e', 'enter'],
    ADD_TRANSACTION: ['a'],
  }

  const handleEditTransaction = () => {
    if (
      !editingTransaction &&
      !isLoading &&
      !isError &&
      transactions &&
      selectedIndex !== null
    ) {
      setEditingTransaction(transactions[selectedIndex])
    }
  }

  const handleAddTransaction = () => {
    setIsInsertingTransaction(true)
    setSelectedIndex(TRANSACTION_NEW_INDEX)
    setEditingTransaction({
      id: TRANSACTION_NEW_ID,
      accountId,
      date: todayAsString(),
      payee: '',
      description: '',
      amount: 0,
      splits: [
        {
          description: '',
          amount: 0,
          category: '',
          tag: '',
        },
      ],
    })
  }

  const handleEditAccount = () => {
    navigate(`/accounts/${accountId}/edit`)
  }

  const keyDependencies = [
    selectedIndex,
    isInsertingTransaction,
    editingTransaction,
    transactions?.length,
  ]

  const keymapHandlers = {
    NEXT_TRANSACTION: useCallback(() => {
      if (isModifyingTransaction()) {
        return
      }
      setSelectedIndex(
        selectedIndex === null
          ? 0
          : Math.min(selectedIndex + 1, (transactions?.length || 0) - 1),
      )
    }, keyDependencies),
    NEXT_TRANSACTION_PAGE: useCallback(() => {
      if (isModifyingTransaction()) {
        return
      }
      setSelectedIndex(
        selectedIndex === null
          ? 0
          : Math.min(selectedIndex + 10, (transactions?.length || 0) - 1),
      )
    }, keyDependencies),
    PREVIOUS_TRANSACTION: useCallback(() => {
      if (isModifyingTransaction()) {
        return
      }
      setSelectedIndex(
        selectedIndex === null ? null : Math.max(selectedIndex - 1, 0),
      )
    }, keyDependencies),
    PREVIOUS_TRANSACTION_PAGE: useCallback(() => {
      if (isModifyingTransaction()) {
        return
      }
      setSelectedIndex(
        selectedIndex === null ? null : Math.max(selectedIndex - 10, 0),
      )
    }, keyDependencies),
    EDIT_TRANSACTION: handleEditTransaction,
    ADD_TRANSACTION: handleAddTransaction,
  }

  let balance = startingBalance

  const handleEditingTransaction = useCallback(
    (transaction: Transaction) => {
      setIsInsertingTransaction(false)
      setEditingTransaction(transaction)
      if (transaction && transactions) {
        setSelectedIndex(transactions.indexOf(transaction))
      }
    },
    [transactions],
  )

  const handleStopEditTransaction = (
    _transaction: Transaction,
    outcome: TransactionEntryEditOutcome,
  ) => {
    setEditingTransaction(null)

    if (isInsertingTransaction) {
      setIsInsertingTransaction(false)
      if (outcome === 'saved') {
        handleAddTransaction()
      } else {
        setSelectedIndex(transactions ? transactions.length - 1 : null)
      }
    }
  }

  const handlePeriodSelect = ({
    startDate: newStartDate,
    endDate: newEndDate,
  }: OnSelectPeriodArgs) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleSearchChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSearchTerm(event.target.value),
    300,
  )

  const renderTransaction = (transaction: Transaction, index: number) => {
    balance += transaction.amount

    return (
      <TransactionEntry
        key={
          transaction.id === TRANSACTION_NEW_ID
            ? generateRandomUUID()
            : transaction.id
        }
        transaction={transaction}
        balance={balance}
        selected={index === selectedIndex}
        editing={editingTransaction?.id === transaction.id}
        onStartEdit={handleEditingTransaction}
        onStopEdit={handleStopEditTransaction}
        onSelect={() => setSelectedIndex(index)}
      />
    )
  }

  const renderHeaders = () => (
    <TransactionPresenter
      date="Date"
      category="Category"
      tag="Tag"
      payee="Payee"
      description="Description"
      amount="Amount"
      balanceOrActions="Balance"
    />
  )

  const renderNewTransaction = () => {
    if (!isInsertingTransaction || !editingTransaction) {
      return (
        <TransactionPresenter
          onClick={() => handleAddTransaction()}
          balanceOrActions={
            <button type="button" onClick={handleAddTransaction}>
              <PlusCircleIcon className="h-5 w-5 text-green-400" />
            </button>
          }
        />
      )
    }
    return renderTransaction(editingTransaction, TRANSACTION_NEW_INDEX)
  }

  const renderSummary = () => (
    <div className="text-right">
      Sum: <MoneyAmount amountInCents={transactionSum} />
    </div>
  )

  useEffect(() => {
    if (transactions && !isLoading) {
      if (focusId) {
        const index = transactions.findIndex((t) => t.id === focusId)
        if (index >= 0) {
          setSelectedIndex(index)
          // Do not clear this if it's not found because the loading triggers the useEffect twice
          setSearchParams({ focusId: '' })
        }
      } else if (selectedIndex === null && transactions.length > 0) {
        setSelectedIndex(transactions.length - 1)
      }
      setEditingTransaction(null)
    }
  }, [accountId, isLoading, transactions])

  if (isError) {
    return <div>Error</div>
  }

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={keymapHandlers} allowChanges>
      <div className="sticky bg-blue-200 p-2 flex justify-between top-0">
        <div className="flex flex-grow">
          <div>
            <PeriodSelect onSelect={handlePeriodSelect} />
          </div>
          <div className="px-2">
            <label htmlFor="transaction-search">
              Search:
              <input
                type="search"
                id="transaction-search"
                className="mx-2"
                onChange={handleSearchChange}
              />
            </label>
          </div>
        </div>
        <div>
          <button type="button" onClick={handleEditAccount}>
            Edit Account
          </button>
        </div>
      </div>
      {!isLoading && (
        <div className="flex-grow">
          <div className="transaction-list p-3 w-full mb-28">
            {renderHeaders()}
            {transactions.map(renderTransaction)}
            {renderNewTransaction()}
            {renderSummary()}
          </div>
        </div>
      )}
    </GlobalHotKeys>
  )
}
