import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getInOutDashboard } from './queries'
import MoneyAmount from '../MoneyAmount'

export default function InOutDashboard() {
  const { data, isLoading } = useQuery({
    queryFn: getInOutDashboard,
    queryKey: ['inOutDashboard'],
  })

  const renderData = () => {
    if (isLoading) {
      return 'Loading...'
    }
    return data?.map((inOutPeriod) => (
      <React.Fragment key={inOutPeriod.periodName}>
        <div>{inOutPeriod.periodName}</div>
        <div className="text-right">
          <MoneyAmount amountInCents={inOutPeriod.in} color showCents={false} />
        </div>
        <div className="text-right">
          <MoneyAmount
            amountInCents={-inOutPeriod.out}
            color
            showCents={false}
          />
        </div>
        <div className="text-right">
          <MoneyAmount
            amountInCents={inOutPeriod.remainder}
            color
            showCents={false}
          />
        </div>
      </React.Fragment>
    ))
  }

  return (
    <div>
      <h1 className="text-center text-lg mb-3">In/Out</h1>
      <div className="grid grid-cols-4 gap-y-1">
        <div>Period</div>
        <div className="text-right text-lg font-bold">+</div>
        <div className="text-right text-lg font-bold">-</div>
        <div className="text-right text-lg font-bold">=</div>
        {renderData()}
      </div>
    </div>
  )
}
