import React, { useState } from 'react'
import { formatDateToYMD } from '../../util'
import DateEditor from '../DateEditor/DateEditor'

export type OnSelectPeriodArgs = {
  startDate: string
  endDate: string
  periodName: string
}

type Props = {
  onSelect: ({ startDate, endDate, periodName }: OnSelectPeriodArgs) => void
}

type PeriodName =
  | 'today'
  | 'this-month'
  | 'last-month'
  | 'last-30-days'
  | 'last-3-months'
  | 'this-year'
  | 'last-6-months'
  | 'last-12-months'
  | 'last-year'
  | 'custom'

const MS_IN_A_DAY = 24 * 60 * 60 * 1000

const getDateRange = (periodName: PeriodName): OnSelectPeriodArgs => {
  switch (periodName) {
    case 'today':
      return {
        startDate: formatDateToYMD(new Date()),
        endDate: formatDateToYMD(new Date()),
        periodName,
      }
    case 'this-month':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        ),
        endDate: formatDateToYMD(new Date()),
        periodName,
      }
    case 'last-month':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        ),
        endDate: formatDateToYMD(
          new Date(new Date().getFullYear(), new Date().getMonth(), 0),
        ),
        periodName,
      }
    case 'last-30-days':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getTime() - 30 * MS_IN_A_DAY),
        ),
        endDate: formatDateToYMD(new Date()),
        periodName,
      }
    case 'last-3-months':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getTime() - 90 * MS_IN_A_DAY),
        ),
        endDate: formatDateToYMD(new Date()),
        periodName,
      }
    case 'this-year':
      return {
        startDate: formatDateToYMD(new Date(new Date().getFullYear(), 0, 1)),
        endDate: formatDateToYMD(new Date()),
        periodName,
      }
    case 'last-6-months':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getTime() - 180 * MS_IN_A_DAY),
        ),
        endDate: formatDateToYMD(new Date()),
        periodName,
      }
    case 'last-12-months':
      return {
        startDate: formatDateToYMD(
          new Date(
            new Date().getFullYear() - 1,
            new Date().getMonth(),
            new Date().getDate(),
          ),
        ),
        endDate: formatDateToYMD(new Date()),
        periodName,
      }
    case 'last-year':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getFullYear() - 1, 0, 1),
        ),
        endDate: formatDateToYMD(
          new Date(new Date().getFullYear() - 1, 11, 31),
        ),
        periodName,
      }
    default:
      return {
        startDate: formatDateToYMD(new Date(0)),
        endDate: formatDateToYMD(new Date('2999-12-31')),
        periodName,
      }
  }
}

const DEFAULT_PERIOD_ID = 'last-3-months'
const CUSTOM_PERIOD_NAME = 'custom'

// This can be customized/saved in the browser
export const defaultSelectPeriod = (): OnSelectPeriodArgs =>
  getDateRange(DEFAULT_PERIOD_ID)

export default function PeriodSelect({ onSelect }: Props) {
  const [startDate, setStartDate] = useState<string>(
    defaultSelectPeriod().startDate,
  )
  const [endDate, setEndDate] = useState<string>(defaultSelectPeriod().endDate)
  const [periodName, setPeriodName] = useState(DEFAULT_PERIOD_ID)

  const customDateRange = periodName === CUSTOM_PERIOD_NAME

  const selectPeriod = ({
    periodName: newPeriodName,
    startDate: newStartDate,
    endDate: newEndDate,
  }: {
    periodName: string
    startDate?: string
    endDate?: string
  }) => {
    if (newStartDate) {
      setStartDate(newStartDate)
    }
    if (newEndDate) {
      setEndDate(newEndDate)
    }
    setPeriodName(newPeriodName)

    onSelect({
      startDate: newStartDate || startDate,
      endDate: newEndDate || endDate,
      periodName: newPeriodName,
    })
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPeriodName = event.target.value as PeriodName

    if (newPeriodName === CUSTOM_PERIOD_NAME) {
      selectPeriod({ periodName: newPeriodName })
    } else {
      const { startDate: newStartDate, endDate: newEndDate } =
        getDateRange(newPeriodName)

      selectPeriod({
        periodName: newPeriodName,
        startDate: newStartDate,
        endDate: newEndDate,
      })
    }
  }

  return (
    <div className="space-x-2">
      <label htmlFor="period-selector">
        Period:
        <select
          id="period-selector"
          onChange={handleSelectChange}
          className="rounded-md"
          value={periodName}
        >
          <option value="all">All</option>
          <option value="today">Today</option>
          <option value="this-month">This Month</option>
          <option value="last-month">Last Month</option>
          <option value="last-30-days">Last 30 Days</option>
          <option value="last-3-months">Last 3 Months</option>
          <option value="last-6-months">Last 6 Months</option>
          <option value="this-year">This Year</option>
          <option value="last-12-months">Last 12 Months</option>
          <option value="last-year">Last Year</option>
          <option value="custom">Custom</option>
        </select>
      </label>

      {customDateRange && (
        <>
          <DateEditor
            className="rounded-md"
            date={startDate}
            disabled={!customDateRange}
            onDateChange={(newDate) =>
              selectPeriod({
                periodName: CUSTOM_PERIOD_NAME,
                startDate: newDate,
              })
            }
          />
          <DateEditor
            className="rounded-md"
            date={endDate}
            disabled={!customDateRange}
            onDateChange={(newDate) =>
              selectPeriod({ periodName: CUSTOM_PERIOD_NAME, endDate: newDate })
            }
          />
        </>
      )}
    </div>
  )
}
