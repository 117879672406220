import { authenticatedFetch } from '../../auth-utils'
import { Tag } from '../types'

export const getCategoriesWithTransferAccounts = async (): Promise<
  string[]
> => {
  const response = await authenticatedFetch(
    '/categories/with_transfer_accounts',
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}

export const getTags = async (): Promise<Tag[]> => {
  const response = await authenticatedFetch('/tags')
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}

export const getPayeeNames = async (): Promise<string[]> => {
  const response = await authenticatedFetch('/payees')
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.json()
}
