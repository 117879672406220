/* eslint-disable func-style */
export function authHeader(): { Authorization: string } {
  const token = localStorage.getItem('token')
  return { Authorization: `${token}` }
}

export function isLoggedIn(): boolean {
  return !!localStorage.getItem('token')
}

export function clearAuthToken(): void {
  localStorage.removeItem('token')
}

export function storeAuthToken(token: string) {
  localStorage.setItem('token', token)
}

function baseUrl() {
  // Env vars starting with REACT_APP_ are automatically injected by CRA during build time.
  return process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'
}

export async function login(email: string, password: string): Promise<boolean> {
  const response = await fetch(`${baseUrl()}/sessions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const { token } = await response.json()

  storeAuthToken(token)

  return true
}

export async function authenticatedFetch(
  path: string,
  options: RequestInit = {},
): Promise<Response> {
  const url = `${baseUrl()}${path}`
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      ...authHeader(),
    },
  })

  if (response.status === 401) {
    clearAuthToken()
    window.location.href = window.location.origin
  }

  if (!response.ok) {
    if (response.headers.get('content-type')?.includes('application/json')) {
      const { error } = await response.json()
      throw new Error(error)
    }
    throw new Error(response.statusText)
  }

  return response
}
