import React from 'react'
import InOutDashboard from './InOutDashboard'
import CashFlowDashboard from './CashFlowDashboard'

export default function MainDashboard() {
  return (
    <div className="p-3 flex gap-3 flex-wrap">
      <div className="bg-orange-200 flex-auto rounded-md p-3 basis-80">
        <InOutDashboard />
      </div>
      <div className="bg-orange-200 flex-auto rounded-md p-3 basis-80">
        <CashFlowDashboard />
      </div>
    </div>
  )
}
