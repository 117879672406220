import React from 'react'
import { login } from '../../auth-utils'

export default function LoginPage(): JSX.Element {
  const [loggingIn, setLoggingIn] = React.useState(false)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget
    const formData = new FormData(form)
    const email = formData.get('email')
    const password = formData.get('password')

    setLoggingIn(true)

    login(email as string, password as string)
      .then(() => {
        window.location.href = '/'
      })
      .catch(() => {
        setLoggingIn(false)
      })
  }

  return (
    <div className="flex w-screen h-screen items-center justify-center">
      <div className="max-w-md bg-yellow-400 p-3 rounded">
        <h1 className="text-center">Login to your account</h1>

        <form onSubmit={handleSubmit}>
          <div className="m-2">
            <label htmlFor="email">
              Email:
              <input
                type="email"
                id="email"
                name="email"
                className="block rounded"
              />
            </label>
          </div>

          <div className="m-2">
            <label htmlFor="password">
              Password:
              <input
                type="password"
                id="password"
                name="password"
                className="block rounded"
              />
            </label>
          </div>

          <div className="m-2 text-center ">
            <button
              type="submit"
              className="bg-blue-500 p-1 rounded"
              disabled={loggingIn}
            >
              {loggingIn ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
