/* eslint-disable func-style */
import React from 'react'
import './App.css'
import './main.css'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'
import MainPage from './components/MainPage'
import LoginPage from './components/Login/LoginPage'
import { isLoggedIn } from './auth-utils'
import AccountEdit from './components/Account/AccountEdit/AccountEdit'
import TransactionList from './components/TransactionList/TransactionList'
import MainDashboard from './components/MainDashboard/MainDashboard'

const queryClient = new QueryClient()

function PrivateRoutes(): JSX.Element {
  return isLoggedIn() ? <Outlet /> : <Navigate to="/signin" />
}

function App() {
  return (
    <div className="text-sm">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route element={PrivateRoutes()}>
              <Route
                path="/"
                element={
                  <MainPage>
                    <MainDashboard />
                  </MainPage>
                }
              />
              <Route path="/accounts" element={<MainPage />} />
              <Route
                path="/accounts/:accountId/transactions"
                element={
                  <MainPage>
                    <TransactionList />
                  </MainPage>
                }
              />
              <Route
                path="/accounts/:accountId/edit"
                element={
                  <MainPage>
                    <AccountEdit />
                  </MainPage>
                }
              />
            </Route>
            <Route path="/signin" element={<LoginPage />} />
            <Route path="/" element={<Navigate to="/accounts" />} />
          </Routes>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
