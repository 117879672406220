import { ReactElement } from 'react'

export default function withEllipsis(content: ReactElement | string) {
  return (
    <div
      className="whitespace-nowrap text-ellipsis overflow-hidden"
      title={content.toString()}
    >
      {content}
    </div>
  )
}
