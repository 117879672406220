import React from 'react'
import PropTypes from 'prop-types'
import { getPayeeNames } from './queries'
import Selector from './Selector'

export default function PayeeSelector({
  payee,
  onChange,
  onKeyDown,
  className,
}: {
  payee: string
  onChange: (c: string) => void
  onKeyDown: (event: React.KeyboardEvent) => void
  className?: string
}) {
  // const getPayees = async (): Promise<string[]> => {
  //   const tags = await getPayees()
  //   return tags.map(({ name }) => name)
  // }

  const handleChange = (newPayee: string) => {
    onChange(newPayee)
  }

  return (
    <Selector
      value={payee}
      queryKey="payees"
      onChange={handleChange}
      onKeyDown={onKeyDown}
      className={className}
      queryFn={getPayeeNames}
      onNewValue={async (p) => {
        // eslint-disable-next-line no-console
        console.log('createPayee', p)
      }}
    />
  )
}

PayeeSelector.propTypes = {
  payee: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
}
