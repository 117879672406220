export type AccountCategory = 'all' | 'cash_flow' | 'net_worth'

export type AccountHiddenFilter = 'all' | 'onlyHidden' | 'onlyVisible'

export type AccountType = 'ASSET' | 'LIABILITY' | 'INCOME' | 'EXPENSE'

export type Account = {
  id: string
  name: string
  balance: number
  hidden: boolean
  type: AccountType
}

export type TransactionSplit = {
  description: string
  amount: number
  category: string
  tag: string
  matchId?: string
  matchAccountId?: string
}

export type Transaction = {
  id: string
  accountId: string
  date: string
  payee: string
  description: string
  amount: number
  splits: TransactionSplit[]
}

export type Category = {
  id: string
  name: string
}

export type Tag = {
  id: string
  name: string
}

export type Payee = {
  name: string
}

export type AccountSummary = {
  totalBalance: number
  netWorthBalance: number
}

export const TRANSACTION_NEW_ID = 'new'
